<template>
  <!-- Main content -->
  <section class="content">
      <div class="btn-box"> 
        <el-switch v-model="activity" @change="circulationAct"></el-switch> 活动循环
      </div>
    <el-table
        v-loading="loading"
        :data="list"
        border>
      <el-table-column label="商品主图">
        <template slot-scope="scope">
            <el-image :src="scope.row.item_pic" :preview-src-list="[scope.row.item_pic]"></el-image>
        </template>
</el-table-column>
<el-table-column label="商品标题" prop="item_title">
    <template slot-scope="scope">
        {{scope.row.loop_title}} {{scope.row.item_title}}
    </template>
</el-table-column>
<el-table-column label="生成时间">
    <template slot-scope="scope">
          {{scope.row.create_time | dateTime}}
        </template>
</el-table-column>
<el-table-column label="状态">
    <template slot-scope="scope">
          {{scope.row.status | status}}
        </template>
</el-table-column>
<el-table-column label="开奖总需次数" prop="total_times"></el-table-column>
<el-table-column label="参与次数" prop="join_times"></el-table-column>
<el-table-column label="参与人数" prop="user_num"></el-table-column>
<el-table-column label="获奖用户">
    <template slot-scope="scope">
    {{scope.row.user_id}}({{scope.row.user_key}})
  </template>
</el-table-column>
<el-table-column label="收货信息">
    <template slot-scope="scope">
      收件人：{{scope.row.receiver}}<br>
      手机号码：{{scope.row.phone}}<br>
      收货地址：{{scope.row.address}}
    </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
    <el-button type="primary" size="mini" v-if="scope.row.status == 1" @click="stop(scope.row.id)">终止活动</el-button></el-button>
  </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'activitySnatchTreasureActivity',
        data() {
            return {
                loading: true,
                activity: false,
                list: [],
                total: 0,
                page: 1,
                pagesize: 10,
                first: false
            }
        },
        watch: {
            // activity() {
            //     this.circulationAct()
            // }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '未开奖'
                        break;
                    case 2:
                        return '已开奖'
                        break;
                    case 3:
                        return '未开奖已被终止'
                        break;
                }
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getSnatchTreasureMaintenance()
        },
        methods: {
            getSnatchTreasureMaintenance(page, size) {
                api.getSnatchTreasureMaintenance({
                    act_item_id: this.$route.query.id,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.list = res.data.list

                    this.activity = res.data.is_loop == 1 ? true : false
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getSnatchTreasureMaintenance()
            },
            currentChange(val) {
                this.page = val
                this.getSnatchTreasureMaintenance()
            },
            stop(id) {
                api.postStopAct({
                    id: id
                }, res => {
                    this.getSnatchTreasureMaintenance()
                })
            },
            circulationAct() {
                api.circulationAct({
                    act_item_id: this.$route.query.id
                }, res => {
                    this.getSnatchTreasureMaintenance()
                    this.first = true
                })
            }
        }
    }
</script>
<style>
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>